.QMsCZ {
  flex-shrink: 0;
  min-width: 0px;
  padding: 20px;
}

.hEcBFA {
  display: flex;
  align-items: center;
}

.hEcBFA > :not(:last-child) {
  margin-right: 16px;
}

.hEcBFA > * {
  flex: 0 0 auto;
}

.fCIktI {
  max-height: 400px;
  overflow: auto;
}

.cGgyBl {
  flex-shrink: 0;
  min-width: 0px;
  padding: 12px 24px 20px;
}

.bUjhUF {
  display: flex;
  flex-direction: column;
}

.GXatJ {
  line-height: 28px;
}

.jfXRgS {
  display: flex;
  flex-flow: row nowrap;
}

.ecKskL {
  min-height: 0px;
  flex-shrink: 0;
  min-width: 52px;
  padding-right: 8px;
}

.eFFLv {
  min-width: 0px;
  min-height: 0px;
  flex: 1 1 0%;
}

.kyojiS {
  overflow-wrap: break-word;
}

.eUnmST {
  flex-shrink: 0;
  min-width: 0px;
  padding: 20px 24px;
}

.klxZek {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(227, 227, 227);
  color: rgb(38, 38, 39);
  position: relative;
  outline: none;
  font-family: inherit;
  border: 0px;
  transition: all 0.4s ease 0s;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  border-radius: 4px;
  padding: 6px 12px;
  min-height: 32px;
  font-size: 14px;
  line-height: 20px;
}

.YOXft {
  display: flex;
}

.klxZek::before {
  content: "";
  position: absolute;
  inset: calc(-2px);
  border-radius: 6px;
  pointer-events: none;
}

.YOXft > * {
  flex: 0 0 auto;
}

.custom-popover .ant-popover-inner {
  border-radius: 8px;
}
.custom-popover .ant-popover-inner-content {
  padding: 0;
}

