.cnpIbj {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: rgb(38, 38, 39);
  background: rgb(250, 250, 250);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.cnpIbj *, .cnpIbj ::before, .cnpIbj ::after {
  box-sizing: inherit;
}

.center {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}