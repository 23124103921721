.cqDPIl {
  border-radius: 6px;
  width: 224px;
  height: 112px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

.hwwBMQ {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
}

.eFFLv {
  min-width: 0px;
  min-height: 0px;
  flex: 1 1 0%;
}

.ZuCOg {
  display: inline-grid;
  grid-auto-flow: column;
  -webkit-box-align: center;
  align-items: center;
  gap: 8px;
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: rgb(251, 161, 55);
}

.ldYDbX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
}

.ldYDbX svg {
  display: block;
  fill: rgb(19, 19, 19);
}

.hxaoxP {
  display: inline;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}


/* 普通bottom样式 */
.cccBxn {
  background: none;
  color: inherit;
  border: none;
  pointer-events: all;
  outline: none;
  height: 7.81818px;
  width: 7.81818px;
  right: -3.90909px;
  padding: 2.90909px;
}

.gSVBBi {
  width: 100%;
  height: 100%;
}

.kNKLJw {
  width: 100%;
  height: 100%;
  display: block;
}

.kNKLJw circle {
  fill: rgb(19, 19, 19);
}

.hctKJM {
  opacity: 0;
}


/* 如果有逻辑右侧箭头 */
.cBOwnG {
  background: none;
  color: inherit;
  border: none;
  pointer-events: all;
  outline: none;
  height: 35.8182px;
  width: 35.8182px;
  right: -17.9091px;
  padding: 2.90909px;
}

.gLiaon {
  padding: 3px;
  width: 100%;
  height: 100%;
}

.kYfQGw {
  pointer-events: all;
  display: block;
  cursor: pointer;
  height: 100%;
  width: 100%;
  color: rgb(38, 38, 39);
}

.fYddpW {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  padding: 0px;
  width: 28px;
  height: 28px;
}

.fYddpW::before {
  content: "";
  position: absolute;
  inset: calc(-3px);
  border-radius: 6px;
  pointer-events: none;
}

.hpSdOY {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: auto;
  height: auto;
}