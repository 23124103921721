.cJveKj {
  pointer-events: all;
  display: block;
  cursor: pointer;
  color: rgb(115, 115, 115);
  transition: color 0.2s ease 0s;
}

.cJveKj:active {
  color: rgb(115, 115, 115);
}

.cJveKj:hover {
  color: rgb(76, 76, 76);
}

.cJveKj:focus {
  outline: 0px;
  color: rgb(76, 76, 76);
}

.cSgtvy {
  fill: rgb(250, 250, 250);
}